import { Button, Col, Container, Row } from "reactstrap";
import { countryCodeEmoji } from "country-code-emoji";
import { faUserMd, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FOOT, KNEE } from "../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../api/Api";
import ApprovalStatus from "./ApprovalStatus";
import ConfirmationModal from "../shared/ConfirmationModal";
import EditUserForm from "../forms/EditUserForm";
import ItemGroup from "../shared/ItemGroup";
import ItemGroupTitle from "../shared/ItemGroupTitle";
import ItemProperty from "../shared/ItemProperty";
import React, { useEffect, useState } from "react";
import TrainingStatus from "./TrainingStatus";
import ViewHeader from "../shared/ViewHeader";

const UserView = () => {
  const navigate = useNavigate();
  const { user_id } = useParams();
  // editing status
  const [editing, setEditing] = useState();
  const toggleEditing = () => setEditing(!editing);

  const [user, setUser] = useState();
  const [confirmPasswordReset, setConfirmPasswordReset] = useState(false);
  const toggleConfirmPasswordReset = () =>
    setConfirmPasswordReset(!confirmPasswordReset);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const toggleDeleteModal = () => setDeleteModalOpen(!deleteModalOpen);

  const isAdmin = (value) => {
    return ['admin', 'admin_with_beta_firmware_access'].includes(value);
  }

  const convertUserType = (value) => {
    if (value === "admin") return "Admin";
    if (value === "admin_with_beta_firmware_access") return "Admin + Beta Firmware Access";
    if (value === "prosthetist") return "Prosthetist";
    return "Unknown";
  }

  const refreshUser = () =>
    Api.get("/users/" + user_id)
      .then((res) => {
        setUser(res.data.user);
      })
      .then(() => setEditing());

  const forgotPassword = () =>
    Api.post("/forgot-password", { email: user.email || "" }).then(() =>
      toggleConfirmPasswordReset()
    );

  const updateUser = async (vals) => {
    Api.patch("/users/" + user?.id, {
      ...vals,
      user_type: vals.user_type.value,
      state: vals.country_code.value === "US" ? vals.state?.value : null,
      country_code: vals.country_code.value,
    }).then(() => refreshUser());
  };

  const deleteUser = () =>
    Api.delete("/users/" + user?.id).then(
      () => {
        toggleDeleteModal();
        navigate("/users");
      },
      (err) => console.error(err)
    );

  useEffect(() => {
    refreshUser();
  }, []);

  return user ? (
    <Container>
      <ViewHeader>
        {user?.full_name}
        <FontAwesomeIcon
          className="ms-3"
          icon={isAdmin(user?.user_type) ? faUserShield : faUserMd}
        />
      </ViewHeader>
      {editing ? (
        <Row>
          <EditUserForm
            admin
            user={user}
            submit={updateUser}
            cancel={toggleEditing}
          />
        </Row>
      ) : (
        <>
          {user && (
            <>
              <ItemGroupTitle title="Details" />
              <ItemGroup>
                <ItemProperty label="First" value={user?.first_name} />
                <ItemProperty label="Last" value={user?.last_name} />
                <ItemProperty label="Email" value={user?.email} />
                <ItemProperty
                  label="Country"
                  value={`${countryCodeEmoji(user?.country_code)} ${
                    user?.country
                  }`}
                />
                <ItemProperty label="City" value={user?.city} />
                {user?.state && (
                  <ItemProperty label="State" value={user?.state} />
                )}
                <ItemProperty label="Account Type" required>
                  {convertUserType(user?.user_type)}
                </ItemProperty>
                <ItemProperty
                  label="Connected Devices"
                  value={user?.device_count}
                />
              </ItemGroup>
              {isAdmin(user?.user_type)=== false && (
                <>
                  <ItemGroupTitle title="Prosthetist Details" />
                  <ItemGroup>
                    <ItemProperty label="Clinic Name" value={user?.clinic} />
                    <ItemProperty label="CPO Number" value={user?.cpo_number} />
                  </ItemGroup>
                  <ItemGroup>
                    <ItemGroupTitle title="Foot Settings" />
                    <ItemProperty
                      required
                      label="Approved for foot use?"
                      value={user?.approved_for_foot_use}>
                      <ApprovalStatus
                        type={FOOT}
                        status={user?.approved_for_foot_use}
                        userId={user?.id}
                        callback={refreshUser}
                      />
                    </ItemProperty>
                    <ItemProperty
                      required
                      label="Completed foot training?"
                      value={user?.has_completed_foot_training}>
                      <TrainingStatus
                        type={FOOT}
                        status={user?.has_completed_foot_training}
                        userId={user?.id}
                        callback={refreshUser}
                      />
                    </ItemProperty>
                  </ItemGroup>
                  <ItemGroup>
                    <ItemGroupTitle title="Knee Settings" />
                    <ItemProperty
                      required
                      label="Approved for knee use?"
                      value={user?.approved_for_knee_use}>
                      <ApprovalStatus
                        type={KNEE}
                        status={user?.approved_for_knee_use}
                        userId={user?.id}
                        callback={refreshUser}
                      />
                    </ItemProperty>
                    <ItemProperty
                      required
                      label="Completed knee training?"
                      value={user?.has_completed_knee_training}>
                      <TrainingStatus
                        type={KNEE}
                        status={user?.has_completed_knee_training}
                        userId={user?.id}
                        callback={refreshUser}
                      />
                    </ItemProperty>
                  </ItemGroup>
                </>
              )}
              <Row className="mt-4">
                <Col className="text-center">
                  <Row>
                    <Col>
                      <Button className="btn-cp" onClick={toggleEditing}>
                        Edit
                      </Button>
                      <Button
                        className="btn-cp"
                        onClick={toggleConfirmPasswordReset}>
                        Reset Password
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        color="danger"
                        className="fw-bold"
                        onClick={toggleDeleteModal}>
                        DELETE
                      </Button>
                    </Col>
                  </Row>
                  <ConfirmationModal
                    title="Reset Password?"
                    body={`Reset password for ${user?.full_name}?`}
                    isOpen={confirmPasswordReset}
                    toggle={toggleConfirmPasswordReset}
                    confirm={forgotPassword}
                  />
                  <ConfirmationModal
                    dangerous
                    title="Delete User?"
                    body={`This will permanently delete ${user?.full_name}'s account and remove them from any connected devices. This action is not reversible. Continue?`}
                    isOpen={deleteModalOpen}
                    toggle={toggleDeleteModal}
                    confirm={deleteUser}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </Container>
  ) : (
    <Row>
      <Col className="text-center m-4">Loading...</Col>
    </Row>
  );
};

export default UserView;
