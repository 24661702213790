import {
  BooleanInput,
  email,
  ReactSelectField,
  reactSelectRequired,
  TextInput,
} from "../shared/FieldInput";
import { Button, Col, Collapse, Row } from "reactstrap";
import { countryCodeEmoji } from "country-code-emoji";
import { Form } from "react-final-form";
import Api from "../../api/Api";
import FormField from "./FormField";
import ItemGroup from "../shared/ItemGroup";
import ItemGroupTitle from "../shared/ItemGroupTitle";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const FieldCol = ({ children }) => (
  <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12" className="my-2">
    {children}
  </Col>
);

FieldCol.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const isAdmin = (value) => {
  return ['admin', 'admin_with_beta_firmware_access'].includes(value);
}

const convertUserTypeToLabel = (value) => {
  if (value === "admin") return "Admin";
  if (value === "admin_with_beta_firmware_access") return "Admin + Beta Firmware Access";
  if (value === "prosthetist") return "Prosthetist";
  return null;
}

const EditUserForm = ({ user = {}, submit, cancel, admin = false }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const userTypes = [
    {label: "Admin", value: "admin"},
    {label: "Admin + Beta Firmware Access", value: "admin_with_beta_firmware_access"},
    {label: "Prosthetist", value: "prosthetist"}
  ]

  useEffect(() => {
    Api.get("user-form").then((res) => {
      setCountries(
        Object.keys(res.data?.countries)
          .filter((x) => x.length < 3)
          .sort((a, b) => {
            if (a === b) return 0;
            if (a === "US") return -1;
            if (b === "US") return 1;
            return a.toUpperCase().localeCompare(b.toUpperCase());
          })
          .map((k) => {
            return {
              label: countryCodeEmoji(k) + " " + res.data?.countries[k],
              value: k,
            };
          })
      );
      setStates(
        Object.keys(res.data?.states).map((k) => {
          return { label: res.data?.states[k], value: res.data?.states[k] };
        })
      );
    });
  }, []);

  return (
    <Form
      onSubmit={submit}
      initialValues={{
        ...user,
        user_type: { label: convertUserTypeToLabel(user?.user_type), value: user?.user_type || "" },
        country_code: {
          label: user?.country
            ? countryCodeEmoji(user?.country_code) + " " + user?.country
            : null,
          value: user?.country_code || "",
        },
        state: { label: user?.state, value: user?.state || "" },
      }}
      render={({ handleSubmit, values, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <ItemGroupTitle title="User Details" />
            <FormField
              className="cp-input"
              component="input"
              label="First Name"
              name="first_name"
              required
            />
            <FormField
              className="cp-input"
              component="input"
              label="Last Name"
              name="last_name"
              required
            />
            <FormField
              className="cp-input"
              component={TextInput}
              validate={email}
              label="Email"
              name="email"
              required
            />
            <FormField
              component={ReactSelectField}
              label="Country"
              name="country_code"
              options={countries}
              required
              validate={reactSelectRequired}
            />
            <FormField
              className="cp-input"
              component="input"
              label="City"
              name="city"
              required
            />
            {values?.country_code?.value === "US" && (
              <FormField
                component={ReactSelectField}
                label="State"
                name="state"
                options={states}
                required
                validate={reactSelectRequired}
              />
            )}
            {admin && (
              <FormField
                component={ReactSelectField}
                label="Account Type"
                name="user_type"
                options={userTypes}
                required
                validate={reactSelectRequired}
              />
            )}
          </Row>
          <Collapse isOpen={!isAdmin(values?.user_type?.value)}>
            {!isAdmin(values?.user_type?.value) && (
            <>
              <ItemGroupTitle title="Prosthetist Details" />
              <Row>
                <FormField
                  label="Clinic"
                  name="clinic"
                  component="input"
                  className="cp-input"
                  required
                />
                <FormField
                  label="CPO Number"
                  name="cpo_number"
                  component="input"
                  className="cp-input"
                />
                {admin && (
                  <>
                    <ItemGroupTitle title="Device Settings" />
                    <ItemGroup>
                      <FormField
                        label="Approved for foot use?"
                        name="approved_for_foot_use"
                        render={BooleanInput}
                        type="checkbox"
                      />
                      <FormField
                        label="Has completed foot training?"
                        name="has_completed_foot_training"
                        render={BooleanInput}
                        type="checkbox"
                      />
                    </ItemGroup>
                    <ItemGroup>
                      <FormField
                        label="Approved for knee use?"
                        name="approved_for_knee_use"
                        render={BooleanInput}
                        type="checkbox"
                      />
                      <FormField
                        label="Has completed knee training?"
                        name="has_completed_knee_training"
                        render={BooleanInput}
                        type="checkbox"
                      />
                    </ItemGroup>
                  </>
                )}
              </Row>
            </>
            )}
          </Collapse>
          <Row className="mt-4">
            <Col className="text-right">
              {cancel && (
                <Button className="mx-1 fw-bold uppercase" onClick={cancel}>
                  Cancel
                </Button>
              )}
              <Button
                className="btn-cp"
                disabled={pristine || submitting}
                type="submit">
                Save
              </Button>
            </Col>
          </Row>
        </form>
      )}></Form>
  );
};

EditUserForm.propTypes = {
  admin: PropTypes.bool,
  user: PropTypes.object,
  submit: PropTypes.func,
  cancel: PropTypes.func,
};

export default EditUserForm;
